// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { Router, Route } from '@redwoodjs/router'

const Routes = () => {
    return ( <Router>
        <
        Route path = "/faq"
        page = { FaqPage }
        name = "faq" / >
        <
        Route path = "/patient"
        page = { PatientPage }
        name = "patient" / >
        <
        Route path = "/index-page"
        page = { IndexPage }
        name = "indexPage" / >
        <
        Route path = "/study-download"
        page = { StudyDownloadPage }
        name = "studyDownload" / >
        <
        Route path = "/profile"
        page = { ProfilePage }
        name = "profile" / >
        <
        Route path="/change-password" 
        page={ChangePasswordPage} 
        name="changePassword" / >
        <
        Route path = "/viewer"
        page = { ViewerPage }
        name = "viewer" / >
        <
        Route path = "/viewer/callback"
        page = { ViewerPage }
        name = "callback" / >
        <
        Route path = "/viewer/projects/{projects}/locations/{locations}/datasets/{datasets}/dicomStores/{dicomStores}"
        page = { ViewerPage }
        name = "viewerGHC" /
        >
        <
        Route path = "/studies"
        page = { StudiesPage }
        name = "studies" / >
        <
        Route path = "/"
        page = { HomePage }
        name = "home" / >
        <
        Route notfound page = { NotFoundPage }
        /> </Router>
    )
}

export default Routes